import React from "react"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import * as Styles from "./index.module.scss"

/* ==================================================
  画像の読み込み
================================================== */

//メインビジュアル
import main from "../../images/p-main.svg"

// flow(アンカーリンク)
import flow01 from "../../images/service/icon_number01.png"
import flow02 from "../../images/service/icon_number02.png"
import flow03 from "../../images/service/icon_number03.png"
import flow04 from "../../images/service/icon_number04.png"

// select（サービスの基本的な流れ）
import service01 from "../../images/service/service01.jpg"
import service02 from "../../images/service/service02.png"
import service03 from "../../images/service/service03.jpg"
import service04 from "../../images/service/service04.jpg"

// request（スポット依頼...）
import request01 from "../../images/service/small_start.jpg"
import request02 from "../../images/service/shopify_tam.jpg"

//各ECカートのロゴ
import ecbeing from "../../images/top/p-logo--ecbeing.png"
import shopify from "../../images/top/p-logo--shopify.png"
import makeshop from "../../images/top/p-logo--makeshop.png"
import futureshop from "../../images/top/p-logo--futureshop.png"
import ebisumart from "../../images/top/p-logo--ebisumart.png"
import melcart from "../../images/top/p-logo--melcart.png"
import ecorigin from "../../images/top/p-logo--ecorigin.png"
import logica from "../../images/top/p-logo--logica.png"
import eccube from "../../images/top/p-logo--eccube.png"
import w2commerceValue5 from "../../images/top/p-logo--w2commerceValue5.png"
import company from "../../images/top/p-logo--company.png"
import repeatplus from "../../images/top/p-logo--repeatplus.png"
import subscstore from "../../images/top/p-logo--subscstore.png"

import serviceIllustTools from "../../images/top/p-service--illustTools.png"

//背景の文字テキスト
import ecarchitect from "../../images/p-bgtext--ecarchitect.svg"
import totalsupport from "../../images/p-bgtext--totalsupport.svg"

//TAMのロゴなど
import tamkun from "../../images/p-logo--tamkun.svg"

/* ==================================================
  コンポーネントの読み込み
================================================= */

import { Form } from "../../components/form"
import scrollTo from "gatsby-plugin-smoothscroll"

const ServicePage: React.VFC = () => {
  return (
    <>
      <SEO title="ECサイトのカート選定から構築・プロモーションまで | TAM" />
      <Layout>
        <div className={Styles.main} id="top">
          <figure className={Styles.topBgHead}>
            <img src={totalsupport} />
          </figure>

          <section className={Styles.top}>
            <div className={Styles.topContainer}>
              <div className={Styles.topText}>
                <h1 className={Styles.topTitle}>
                  ECサイトを
                  <br />
                  トータルサポート
                </h1>
                <div className={Styles.topTitleSub}>
                  対応可能なECプラットフォーム業界最多
                  <span className={Styles.super}>※</span> <br />
                  課題抽出から運用までワンストップ
                </div>
                <a
                  onClick={() => scrollTo("#contact")}
                  className={Styles.btnContact}
                >
                  今すぐお問い合わせ
                </a>
              </div>
              <figure className={Styles.topImg}>
                <img
                  src={main}
                  alt="メインビジュアル"
                  width="542px"
                  height="600px"
                />
              </figure>
            </div>
            <div className={Styles.topTextUnder}>※弊社調べ</div>
            <figure className={Styles.topBgUnder}>
              <img src={ecarchitect} alt="ecarchitectのテキスト画像" />
            </figure>
          </section>

          <div className={Styles.flow}>
            <div className={Styles.container}>
              <ul className={Styles.flowList}>
                <li className={Styles.flowContent}>
                  <div className={Styles.flowNumber}>
                    <img
                      data-src={flow01}
                      className="lazyload"
                      alt="01イラスト"
                      width="229"
                      height="137"
                    />
                  </div>
                  <div className={Styles.flowTextWrap}>
                    <p className={Styles.flowText}>
                      課題発見・戦略・
                      <br />
                      プランニング
                    </p>
                  </div>
                </li>
                <li className={Styles.flowContent}>
                  <div className={Styles.flowNumber}>
                    <img
                      data-src={flow02}
                      className="lazyload"
                      alt="02イラスト"
                      width="206"
                      height="137"
                    />
                  </div>
                  <div className={Styles.flowTextWrap}>
                    <p className={Styles.flowText}>
                      カート選定
                      <br />
                      サポート
                    </p>
                  </div>
                </li>
                <li className={Styles.flowContent}>
                  <div className={Styles.flowNumber}>
                    <img
                      data-src={flow03}
                      className="lazyload"
                      alt="03イラスト"
                      width="201"
                      height="162"
                    />
                  </div>
                  <div className={Styles.flowTextWrap}>
                    <p className={Styles.flowText}>
                      ECサイト
                      <br />
                      構築
                    </p>
                  </div>
                </li>
                <li className={Styles.flowContent}>
                  <div className={Styles.flowNumber}>
                    <img
                      data-src={flow04}
                      className="lazyload"
                      alt="04イラスト"
                      width="195"
                      height="159"
                    />
                  </div>
                  <div className={Styles.flowTextWrap}>
                    <p className={Styles.flowText}>
                      PDCA運用
                      <br />
                      プロモーション
                    </p>
                  </div>
                </li>
              </ul>
              <p className={Styles.flowAnnotation}>
                ※英語版越境ECの対応も可能 <br className={Styles.spOnly} />
                ※スポットでの対応も可能
              </p>
            </div>
          </div>

          <section className={Styles.problem}>
            <div className={Styles.problemLogoWrap}>
              <figure className={Styles.problemLogo}>
                <img
                  className={Styles.problemImg}
                  src={tamkun}
                  alt="タムくん"
                  width="247"
                  height="439"
                />
              </figure>
            </div>
            <div className={Styles.problemContainer}>
              <h2 className={Styles.problemTitle}>
                こんなお悩み
                <br className={Styles.spOnly} />
                解決します
              </h2>
              <ul className={Styles.problemList}>
                <li className={Styles.problemItem}>
                  世の中にたくさん存在する EC
                  カート。どれを選ぶのが正解なのだろうか。
                </li>
                <li className={Styles.problemItem}>
                  EC カートを選定したものの、発注先の開発企業が EC
                  カートを使って開発できない。
                </li>
                <li className={Styles.problemItem}>
                  EC
                  サイトができたものの、運用しやすい設計になっていない。リリース後に運用しづらい。
                </li>
                <li className={Styles.problemItem}>
                  EC
                  サイトもできて、運用も整えたのに、集客がうまくいかない。売り上げが伸びない。
                </li>
              </ul>
            </div>
          </section>

          <section className={Styles.cartArea}>
            <div className={Styles.container}>
              <h2 className={Styles.cartAreaTitle}>
                国内外10種類の
                <br className={Styles.spOnly} />
                カートに対応
              </h2>
              <p className={Styles.cartAreaText}>
                高いフロントエンド技術で、さまざまなカートCMSに対応可能です。
              </p>
            </div>
            <div className={Styles.cart}>
              <div className={Styles.container}>
                <ul className={Styles.cartLists}>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ecbeing}
                      className="lazyload"
                      alt="ecbeingロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={shopify}
                      className="lazyload"
                      alt="shopifyロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={makeshop}
                      className="lazyload"
                      alt="makeshopロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={futureshop}
                      className="lazyload"
                      alt="futureshopロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ebisumart}
                      className="lazyload"
                      alt="ebisumartロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={melcart}
                      className="lazyload"
                      alt="melcartロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={ecorigin}
                      className="lazyload"
                      alt="ecoriginロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={logica}
                      className="lazyload"
                      alt="logicaロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={eccube}
                      className="lazyload"
                      alt="eccubeロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={w2commerceValue5}
                      className="lazyload"
                      alt="w2commerceValue5ロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={company}
                      className="lazyload"
                      alt="companyロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={repeatplus}
                      className="lazyload"
                      alt="repeatplusロゴ"
                    />
                  </li>
                  <li className={Styles.cartLogoBlock}>
                    <img
                      data-src={subscstore}
                      className="lazyload"
                      alt="subscstoreロゴ"
                    />
                  </li>
                </ul>
                <div className={Styles.cartBgIllust}>
                  <img
                    data-src={serviceIllustTools}
                    className="lazyload"
                    alt="背景の工具のイラスト"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className={Styles.service}>
            <div className={Styles.container}>
              <h2 className={Styles.serviceTitle}>
                サービスの
                <br className={Styles.spOnly} />
                基本的な流れ
              </h2>
              <p className={Styles.serviceText}>
                課題発見から戦略立案、カート選定にサイト構築、PDCA運用、プロモーションまでワンストップで提供しています。
                <br />
                クライアントと共通のゴールを目指し、制作だけでなく、ワークショップによるサイトコンセプトの提案、
                <br className={Styles.pcOnly} />
                集客のためのSEO・広告・SNS活用、OMO推進、WEB接客やMA・CRMのシナリオ設計などECのトータルサポートを行っています。
              </p>
              <ol className={Styles.serviceList}>
                <li className={Styles.serviceContent}>
                  <div className={Styles.serviceTextBlock}>
                    <div className={Styles.serviceNumber}>
                      0<span>1</span>
                    </div>
                    <div className={Styles.serviceTextContent}>
                      <h3 className={Styles.serviceMidheading}>
                        課題発見・戦略・
                        <br className={Styles.spOnly} />
                        プランニング
                      </h3>
                      <dl>
                        <dt className={Styles.serviceSubheading}>与件の整理</dt>
                        <dd className={Styles.serviceText}>
                          企業様がTAMに依頼をいただいた背景や現状の課題、目的を整理します。
                          <br />
                          事前に企業様とTAMで共通の認識を持つことでスムーズにプロジェクトを
                          進行することが可能です。
                        </dd>
                        <dt className={Styles.serviceSubheading}>
                          リフレーミング
                        </dt>
                        <dd className={Styles.serviceText}>
                          与件を整理した上で、課題の枠組みを様々な方向から見直します。
                          <br />
                          これにより、今までに気付いていなかった新たな課題や
                          <br className={Styles.pcOnly} />
                          目的を発見することが可能です。
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className={Styles.serviceImg}>
                    <img
                      data-src={service01}
                      className="lazyload"
                      alt="課題発見・戦略・プランニング"
                      width="541"
                      height="441"
                    />
                  </div>
                </li>
                <li className={Styles.serviceContent}>
                  <div className={Styles.serviceTextBlock}>
                    <div className={Styles.serviceNumber}>
                      0<span>2</span>
                    </div>
                    <div className={Styles.serviceTextContent}>
                      <h3 className={Styles.serviceMidheading}>
                        カート選定サポート
                      </h3>
                      <dl>
                        <dt className={Styles.serviceSubheading}>カート提案</dt>
                        <dd className={Styles.serviceText}>
                          ECサイトの専門家として特定のECカートに依存することなく、
                          <br className={Styles.pcOnly} />
                          常に10種類以上のECカートの中から公平な目線で、クライアント＆
                          <br className={Styles.pcOnly} />
                          ユーザーが本当に使いやすいサービスをご提案しています。
                          <br />
                          クライアントのRFPを元にカート候補を複数選定、必要に応じて
                          <br className={Styles.pcOnly} />
                          ご紹介・ヒアリング同席、構築時にはECプラットフォーム開発者との
                          <br className={Styles.pcOnly} />
                          代理コミュニケーションが可能です。
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className={Styles.serviceImg}>
                    <img
                      data-src={service02}
                      className="lazyload"
                      alt="カート選定サポート"
                      width="541"
                      height="441"
                    />
                  </div>
                </li>
                <li className={Styles.serviceContent}>
                  <div className={Styles.serviceTextBlock}>
                    <div className={Styles.serviceNumber}>
                      0<span>3</span>
                    </div>
                    <div className={Styles.serviceTextContent}>
                      <h3 className={Styles.serviceMidheading}>サイト構築</h3>
                      <dl>
                        <dt className={Styles.serviceSubheading}>デザイン</dt>
                        <dd className={Styles.serviceText}>
                          ワイヤーフレーム・スタイルスケープをもとに、EC専門の
                          <br className={Styles.pcOnly} />
                          デザイナーがECカートに最適化したデザインを作成します。
                        </dd>
                        <dt className={Styles.serviceSubheading}>
                          コーディング
                        </dt>
                        <dd className={Styles.serviceText}>
                          経験豊富なエンジニアが多数所属しており、様々なカートに
                          <br className={Styles.pcOnly} />
                          対応できるだけでなく、SEOなど検索エンジンに対する対策も
                          <br className={Styles.pcOnly} />
                          行うことが可能です。
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className={Styles.serviceImg}>
                    <img
                      data-src={service03}
                      className="lazyload"
                      alt="サイト構築"
                      width="540"
                      height="440"
                    />
                  </div>
                </li>
                <li className={Styles.serviceContent}>
                  <div className={Styles.serviceTextBlock}>
                    <div className={Styles.serviceNumber}>
                      0<span>4</span>
                    </div>
                    <div className={Styles.serviceTextContent}>
                      <h3 className={Styles.serviceMidheading}>
                        PDCA運用・
                        <br className={Styles.spOnly} />
                        プロモーション
                      </h3>
                      <dl>
                        <dt className={Styles.serviceSubheading}>PDCA運用</dt>
                        <dd className={Styles.serviceText}>
                          ECサイトの制作後も継続的にPDCAを回すことで、
                          <br className={Styles.pcOnly} />
                          サイト改善に取り組んでいきます。
                          <br />
                          <span>
                            ※弊社でお受けしているのはデジタルマーケティングおよび制作運用のみです。
                            <br className={Styles.pcOnly} />
                            受発注業務や物流・コールセンター業務は対応しておりません。
                          </span>
                        </dd>
                        <dt className={Styles.serviceSubheading}>
                          プロモーション
                        </dt>
                        <dd className={Styles.serviceText}>
                          TAM 広告チームがフルファネルマーケティング提案の
                          <br className={Styles.pcOnly} />
                          エージェンシーとして、デジタルマーケティングから
                          <br className={Styles.pcOnly} />
                          運用型広告まで企業様と伴走します
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className={Styles.serviceImg}>
                    <img
                      data-src={service04}
                      className="lazyload"
                      alt="PDCA運用・プロモーション"
                      width="540"
                      height="440"
                    />
                  </div>
                </li>
              </ol>
            </div>
          </section>

          <section className={Styles.request}>
            <div className={Styles.container}>
              <h2 className={Styles.requestTitle}>
                スポットでのご依頼も
                <br className={Styles.spOnly} />
                ご相談ください。
              </h2>
              <p className={Styles.requestText}>
                ECサイト構築のみ、各種ランディングページやバナーなどの制作運用のみ、
                <br className={Styles.pcOnly} />
                といったスポットでのご依頼もお気軽にご相談ください。
              </p>
              <ul className={Styles.requestList}>
                <li className={Styles.requestContent}>
                  <Link to="/service/package/">
                    <h3 className={Styles.requestSubheading}>
                      ECのスモールスタートを応援
                    </h3>
                    <p className={Styles.requestText}>
                      まずはECサイトを作成したい。という希望にお答えします！
                      ビジネスの成長に合わせたECサイト構築が可能です。
                    </p>
                    <div className={Styles.requestImg}>
                      <img
                        data-src={request01}
                        className="lazyload"
                        alt="SMALL START 応援プラン"
                        width="380"
                        height="254"
                      />
                    </div>
                  </Link>
                </li>
                <li className={Styles.requestContent}>
                  <a href="https://cross-border.oh-tam.com/" target="_blank">
                    <h3 className={Styles.requestSubheading}>
                      英語版越境EC×shopify
                    </h3>
                    <p className={Styles.requestText}>
                      越境ECなら世界NO.1シェアのshopifyがオススメ！TAMでは、英語と⽇本語に対応した越境ECサイトの
                      構築が可能です。
                    </p>
                    <div className={Styles.requestImg}>
                      <img
                        data-src={request02}
                        className="lazyload"
                        alt="越境ECなら Shopify×TAM"
                        width="380"
                        height="254"
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </section>

          <Form />
        </div>
      </Layout>
    </>
  )
}

export default ServicePage
